import React, { useState, useEffect, useCallback } from 'react';
import {
  Anchor,
  Avatar,
  Box,
  Button,
  Grommet,
  Heading,
  Main,
  Text,
  Layer,
  Paragraph,
  grommet,
} from 'grommet';
import { Helmet } from 'react-helmet';
import { deepMerge } from 'grommet/utils';
import { FormClose } from 'grommet-icons';
import deepEqual from 'deep-equal';

import socketClusterClient from 'socketcluster-client';

import { PrivacyNotice } from './PrivacyNotice.js';
import { MSCStatus } from './MSCStatus.js';
import { questionComponents } from './question-components/index.js';
import { IccIcon } from './Icons.js';
import {
  subscriberFactory,
  unsubscriberFactory,
  rpcInvokerFactory,
  namer as eventNamer,
} from 'icc-lib';

// MARK - UI

const theme = deepMerge(grommet, {
  global: {
    colors: {
      brand: 'rgb(255, 43, 206)',
    },
  },
});

const AppBar = (props) => (
  <Box
    tag='header'
    direction='row'
    align='center'
    justify='between'
    background='brand'
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation='medium'
    flex={false}
    {...props}
  />
);

const Sidebar = (props) => (
  <MSCStatus
    slug={props.slug}
    datasetID={props.datasetID}
    socket={props.socket}
    user={props.user}
  />
);

const QuestionPre = () => (
  <>
    <Paragraph>Welcome to our community mapping survey.</Paragraph>
    <Paragraph>
      <em>
        Please note that you should only complete this survey on behalf of an
        organisation.
      </em>{' '}
      (By ‘organisation’ we also mean informal community groups, not just large
      constituted organisations)
    </Paragraph>
    <Paragraph>
      In this survey we will be asking you 14 short questions about your
      organisation, its goals and its connections within and outside of your
      community.
    </Paragraph>
    <Paragraph>
      This exercise is not about gathering perfect data. The answers that spring
      to mind as you answer tend to be the right ones. What we’re looking at
      here is your general direction of travel, the patterns that form your
      community, and following on from that, being able to spot gaps and
      opportunities to build your resilience as a community.
    </Paragraph>
    <Paragraph>
      To continue on to the Mapping process you will need to read and agree to
      our Data policy.
    </Paragraph>
    <PrivacyNotice />
    <Heading level={2}>Your consent</Heading>
    <Paragraph>
      By continuing to the mapping process, you are agreeing to this privacy
      notice
    </Paragraph>
  </>
);

const QuestionPost = () => (
  <>
    <Paragraph>
      Thank you for taking the time to answer these questions.
    </Paragraph>
    <Paragraph>
      This is the community map that you have made:{' '}
      <Anchor
        href={`${window.location.href.replace(/\/$/, '')}/network`}
        label={`${window.location.href.replace(/\/$/, '')}/network`}
      />
    </Paragraph>
    <Paragraph>
      Please remember that as a contributor to the map you have privileged
      access to it and the personal data it contains (people’s names). As such,
      please do not ever keep, copy or share this map with anyone else.
    </Paragraph>
  </>
);

const Question404 = () => (
  <>
    <Paragraph>
      This is a holding page, pending the full <i>understory.community</i> site.
    </Paragraph>
  </>
);

const Question403 = () => (
  <>
    <Paragraph>
      We do not have you as a member of this community, and so you cannot
      contribute to its mapping.
    </Paragraph>
    <Paragraph>
      If you consider yourself part of this community, please contact its anchor
      organisation who manage membership and can add your account if
      appropriate.
    </Paragraph>
    <Paragraph>
      You can view the public version of the community map here:{' '}
      <Anchor
        href={`${window.location.href.replace(/\/$/, '')}/network`}
        label={`${window.location.href.replace(/\/$/, '')}/network`}
      />
    </Paragraph>
  </>
);

const App = () => {
  const [socket, setSocket] = useState(null);
  const [user, setUser] = useState();
  useEffect(() => {
    const options = process.env.NODE_ENV === 'production' ? {} : { port: 9000 };
    const s = socketClusterClient.create(options);

    (async () => {
      for await (let { error } of s.listener('error')) {
        console.error('Socket error: ', error);
      }
    })();

    (async () => {
      for await (let event of s.listener('connect')) {
        console.log('Socket is connected');
        setSocket(s);

        const response = await fetch('/user', { credentials: 'same-origin' });
        if (response.ok) {
          const json = await response.json();
          if (!s.authToken || s.authToken.id !== json.authToken.id) {
            await s.authenticate(json.signedAuthToken);
          } else {
            console.log('Socket authentication still valid');
          }
          setUser(json.userInfo); // Always trigger get project attempt
        } else if (response.status === 403) {
          s.deauthenticate();
          setUser();
          // If we're on a project page and so require auth, reload to trigger session authentication
          // If we're not, don't as otherwise it'll refresh infinitely.
          if (window.location.pathname !== '/') {
            window.location.reload();
          }
        } else {
          console.error('Could not fetch /user');
        }
      }
    })();

    (async () => {
      for await (let event of s.listener('authenticate')) {
        console.log('Socket is authenticated. ', event.authToken);
      }
    })();

    return () => {
      s.disconnect();
    };
  }, []);

  const [showSidebar, setShowSidebar] = useState(false);

  const [projectSlug, setProjectSlug] = useState('');
  const [datasetID, setDatasetID] = useState(null);
  const questionPreIndex = -1;
  const [questionPostIndex, setQuestionPostIndex] = useState(0);
  const [questionIndex, setQuestionIndex] = useState();

  useEffect(() => {
    if (!socket || !user) {
      return;
    }

    if (socket.authState !== socket.AUTHENTICATED) {
      console.warn('Attempting project rpc when not authenticated');
    }

    const currentPath = window.location.pathname;
    let slug;
    try {
      slug = currentPath.split('/')[1];
    } catch {
      console.log('Could not parse location for project.', currentPath);
      return;
    }
    if (slug !== projectSlug) {
      console.log(`Attempting to get project for '${slug}'`);
      const rpc = rpcInvokerFactory(socket, 'project');
      rpc({
        payload: slug,
        handler: (result) => {
          console.log(result);
          // If we receive content, we are a member of that project
          if (result.content) {
            setProjectSlug(slug);
            setDatasetID(result.datasetID);
            setQuestionIndex(questionPreIndex);
            setQuestionPostIndex(result.content.questions.length);
          }
          // If not, we are not and should show the 403 message
          else {
            setProjectSlug('403');
          }
        },
      });
    }
  }, [socket, user, projectSlug, questionPreIndex]);

  const [labelOrganisation, setLabelOrganisation] = useState(
    'Mapping Social Capital'
  );
  useEffect(() => {
    if (!socket || !datasetID) {
      return;
    }

    const subscribe = subscriberFactory(
      socket,
      eventNamer(datasetID, 'labelOrganisation')
    );
    subscribe({
      handler: (data) => setLabelOrganisation(data.data),
    });
    return unsubscriberFactory(
      socket,
      eventNamer(datasetID, 'labelOrganisation')
    );
  }, [socket, datasetID]);

  const [labelProject, setLabelProject] = useState('');
  useEffect(() => {
    if (!socket || !datasetID) {
      return;
    }

    const subscribe = subscriberFactory(
      socket,
      eventNamer(datasetID, 'labelProject')
    );
    subscribe({
      handler: (data) => setLabelProject(data.data),
    });
    return unsubscriberFactory(socket, eventNamer(datasetID, 'labelProject'));
  }, [socket, datasetID]);

  const [question, setQuestion] = useState(null);
  useEffect(() => {
    if (!socket || !datasetID || questionIndex === undefined) {
      return;
    }

    if (
      questionIndex <= questionPreIndex ||
      questionIndex >= questionPostIndex
    ) {
      return;
    }

    // Issue: submit can be called before operation completes.
    // Quick fix: stop existing submit leaking across by clearing submit data state
    // Alternative would be some logic around 'questionHasCompletedLoad'
    setSubmitData();
    setData();

    const name = eventNamer(datasetID, 'question', questionIndex);
    const subscribe = subscriberFactory(socket, name);
    subscribe({
      handler: (result) => {
        console.log(`Set question ${questionIndex}`, result);
        setSubmitData(result.data.submit);
        setQuestion({ ...result.data, id: questionIndex });
      },
    });
    return unsubscriberFactory(socket, name);
  }, [socket, datasetID, questionIndex, questionPreIndex, questionPostIndex]);

  const [submitData, setSubmitData] = useState();
  const [data, setData] = useState();
  const [valid, setValid] = useState(false);

  const submit = (onComplete) => {
    if (
      data === undefined ||
      (submitData !== undefined && deepEqual(data, submitData))
    ) {
      console.log('Not submitting ', JSON.stringify(data));
      onComplete();
      return;
    }

    console.log('Submitting ', JSON.stringify(data));
    const rpc = rpcInvokerFactory(
      socket,
      eventNamer(datasetID, 'question', question.id)
    );
    rpc({
      payload: { submit: data },
      handler: (result) => {
        console.log('Commit received ', JSON.stringify(result.data));
        onComplete();
      },
    });

    setSubmitData(data);
    setData();
  };

  const submitContentUpdate = useCallback(
    (updateData) => {
      console.log('Updating ', JSON.stringify(updateData));
      const rpc = rpcInvokerFactory(
        socket,
        eventNamer(datasetID, 'question', question.id)
      );
      rpc({
        payload: { update: updateData },
        handler: (result) =>
          console.log('Update received ', JSON.stringify(result.data)),
      });
    },
    [socket, datasetID, question]
  );

  const QuestionComponent = question && questionComponents[question.kind];
  console.log('QuestionIndex', questionIndex);
  return (
    <Grommet theme={theme} full>
      <Helmet>
        <title>
          {labelProject
            ? `${labelOrganisation
                .split(/[\s-]/)
                .map((word) => word[0])
                .join('')
                .toUpperCase()} – ${labelProject}`
            : labelOrganisation}
        </title>
      </Helmet>
      <Main>
        <AppBar>
          <Heading level='3' margin='none'>
            {labelOrganisation}
          </Heading>
          <Button
            icon={
              (user?.picture && <Avatar size='small' src={user?.picture} />) ||
              (user && (
                <Avatar size='small' border={true}>
                  <Text>{user.nickname}</Text>
                </Avatar>
              )) || <IccIcon />
            }
            pad='none'
            onClick={() => setShowSidebar(!showSidebar)}
          />
        </AppBar>
        <Box pad={{ left: 'medium', right: 'small', vertical: 'small' }}>
          {questionIndex === undefined ? (
            projectSlug === '403' ? (
              <Question403 />
            ) : (
              <Question404 />
            )
          ) : (
            <>
              <Heading level='1'>{labelProject}</Heading>

              {questionIndex <= questionPreIndex && <QuestionPre />}

              {question &&
                questionIndex > questionPreIndex &&
                questionIndex < questionPostIndex && (
                  <QuestionComponent
                    {...question}
                    onData={setData}
                    onContentUpdate={submitContentUpdate}
                    onValid={setValid}
                  />
                )}

              {questionIndex >= questionPostIndex && <QuestionPost />}

              <Box
                flex={false}
                direction='row'
                gap='medium'
                pad={{ bottom: 'large' }}
              >
                {questionIndex > questionPreIndex && (
                  <Button
                    label='Previous'
                    onClick={() => {
                      const onComplete = () => {
                        setQuestionIndex(questionIndex - 1);
                      };
                      submit(onComplete);
                    }}
                  />
                )}
                {questionIndex < questionPostIndex && (
                  <Button
                    primary
                    label='Next'
                    disabled={!(questionIndex <= questionPreIndex || valid)}
                    onClick={(e) => {
                      const onComplete = () => {
                        setQuestionIndex(questionIndex + 1);
                      };
                      submit(onComplete);
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
        {showSidebar && (
          <Layer>
            <Box
              background='light-2'
              tag='header'
              justify='end'
              align='center'
              direction='row'
            >
              <Button
                icon={<FormClose />}
                onClick={() => setShowSidebar(false)}
              />
            </Box>
            <Box fill background='light-2' align='center' justify='center'>
              <Sidebar
                slug={projectSlug}
                datasetID={datasetID}
                socket={socket}
                user={user}
              />
            </Box>
          </Layer>
        )}
      </Main>
    </Grommet>
  );
};

export default App;
